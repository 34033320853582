import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
} from '@angular/core';
import { DropdownService } from './dropdown.service';
import { Subject, map, takeUntil, tap } from 'rxjs';
import { GeneralDataFacade } from '@/_store/general/general.facade';

@Component({
	selector: 'app-dropdown',
	template: `
		<ul
			*ngIf="isOpen"
			class="dropdown border-box z-10 mb-5"
			[ngClass]="{
				scrollable: scrollable,
				mobile: isMobileAndScrollable$ | async,
			}"
			(click)="stopPropagation($event)"
		>
			<ng-content></ng-content>
		</ul>
	`,
	styles: [
		`
			:host {
				--radius: 0;
				--border-style: solid;
				--border-width: 0;
				--border-color: #000;
				--shadow: 0;
				---right: auto;
			}

			.dropdown {
				padding: 10px;
				position: absolute;
				background-color: #fff;
				z-index: 1000;
				right: var(--right);
				border-width: var(--border-width);
				border-color: var(--border-color);
				border-style: var(--border-style);
				border-radius: var(--radius);
				box-shadow: var(--shadow);
				border: 1px solid #eee;
				border-top: none;
			}

			.scrollable {
				max-height: 250px;
				overflow: hidden;
				overflow-y: scroll;
			}

			.mobile {
				max-height: 150px;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DropdownService],
})
export class DropdownComponent implements OnInit, OnDestroy {
	@Input() isOpen = false;

	@Input() scrollable = true;

	@Input() width = '100%';

	@Input() centerHorizontally = false;

	@Output() closeDropdownEvent: EventEmitter<void> = new EventEmitter();

	@Output() dropdownStateChange: EventEmitter<boolean> = new EventEmitter();

	isMobileAndScrollable$ = this.generalDataFacade.isMobile$.pipe(map((isMobile) => isMobile && this.scrollable));

	private readonly destroy$: Subject<void> = new Subject<void>();

	constructor(
		public elRef: ElementRef,
		private readonly renderer: Renderer2,
		private readonly cdr: ChangeDetectorRef,
		private readonly dropdownService: DropdownService,
		private readonly generalDataFacade: GeneralDataFacade,
	) {}

	ngOnInit(): void {
		this.dropdownService.closeDropdown$
			.pipe(
				takeUntil(this.destroy$),
				tap(() => this.closeDropdown()),
			)
			.subscribe();

		this.dropdownService.triggerDropdown$
			.pipe(
				takeUntil(this.destroy$),
				tap(() => this.triggerDropdown()),
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	stopPropagation(event: Event): void {
		event.stopPropagation();
	}

	triggerDropdown(): void {
		this.isOpen = !this.isOpen;
		this.cdr.detectChanges();
		this.dropdownStateChange.emit(this.isOpen);
		this.setDropdownStyles();
	}

	closeDropdown(): void {
		this.isOpen = false;
		this.closeDropdownEvent.emit();
		this.dropdownStateChange.emit(this.isOpen);
		this.cdr.detectChanges();
	}

	private setDropdownStyles() {
		const dropdownRef = this.elRef.nativeElement.querySelector('ul');
		if (dropdownRef) {
			this.renderer.setStyle(dropdownRef, 'width', this.width);
			if (this.centerHorizontally) {
				this.renderer.setStyle(dropdownRef, 'transform', 'translate(-50%, 0)');
			}
		}
	}
}
